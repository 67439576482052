.banner {
    font-size: 17px;
    font-weight: $font-weight-normal;
    padding: 13px 0;
    margin-bottom: 0;
    border-radius: 0;
    border: none;

    .banner-container {
        display: flex;
        align-items: center;
        margin-left: calc($grid-gutter-width / 4) * -1;
        margin-right: calc($grid-gutter-width / 4) * -1;

        > * {
            padding-left: calc($grid-gutter-width / 4);
            padding-right: calc($grid-gutter-width / 4);
        }

        @include media-breakpoint-up(md) {
            margin-left: calc($grid-gutter-width / 2) * -1;
            margin-right: calc($grid-gutter-width / 2) * -1;

            > * {
                padding-left: calc($grid-gutter-width / 2);
                padding-right: calc($grid-gutter-width / 2);
            }
        }

        .banner-content {
            width: 100%;
            text-align: center;

            @include media-breakpoint-up(md) {
                padding-right: $grid-gutter-width;
            }

            @if $isTc {
                .strong-desk {
                    font-weight: 700;
                }

                @include media-breakpoint-down(md) {
                    padding-right: 20px;

                    .strong-mb {
                        font-weight: 700;
                    }

                    .strong-desk {
                        font-weight: initial;
                    }
                }
            }
        }

        .banner-close {
            display: flex;
            align-items: center;
            width: 40px;
            flex-shrink: 0;
            font-size: 30px;
            line-height: 20px;
            cursor: pointer;
            padding: 6px 15px;

            &::before {
                content: "";
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.46281 9.06122L4.50374 7.10216L2.53719 9.06721C1.95756 9.64834 1.01548 9.64834 0.435846 9.06721C-0.145282 8.48758 -0.145282 7.547 0.435846 6.96587L2.4009 4.99932L0.435846 3.03426C-0.145282 2.45314 -0.145282 1.51255 0.435846 0.932917C1.01548 0.351788 1.95756 0.351788 2.53719 0.932917L4.50374 2.89797L6.46281 0.938908C7.04244 0.357779 7.98303 0.357779 8.56415 0.938908C9.14378 1.52004 9.14378 2.46063 8.56415 3.04026L6.60509 5.00081L8.56415 6.95988C9.14528 7.53951 9.14528 8.48159 8.56415 9.06122C7.98452 9.64085 7.04244 9.64085 6.46281 9.06122Z' fill='black'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                width: 10px;
                height: 10px;
            }

            span {
                display: none;
            }
        }
    }

    &.banner-promo {
        @include alert-variant($blue-gradient-base, transparent, $white);

        background: $blue-gradient;

        .banner-policy-link {
            color: $white;
        }

        .banner-close {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.46281 9.06122L4.50374 7.10216L2.53719 9.06721C1.95756 9.64834 1.01548 9.64834 0.435846 9.06721C-0.145282 8.48758 -0.145282 7.547 0.435846 6.96587L2.4009 4.99932L0.435846 3.03426C-0.145282 2.45314 -0.145282 1.51255 0.435846 0.932917C1.01548 0.351788 1.95756 0.351788 2.53719 0.932917L4.50374 2.89797L6.46281 0.938908C7.04244 0.357779 7.98303 0.357779 8.56415 0.938908C9.14378 1.52004 9.14378 2.46063 8.56415 3.04026L6.60509 5.00081L8.56415 6.95988C9.14528 7.53951 9.14528 8.48159 8.56415 9.06122C7.98452 9.64085 7.04244 9.64085 6.46281 9.06122Z' fill='white'/%3E%3C/svg%3E");
            }
        }
    }

    &.banner-warning {
        @include alert-variant($yellow-gradient-base, transparent, $black);

        background: $yellow-gradient, $white;
    }

    &.banner-pink {
        @include alert-variant($pink-gradient-base, transparent, $black);

        background: $pink-gradient, $white;
    }

    &.banner-gradient {
        background-color: #f4f4f4;
    }

    &.banner-rounded {
        border-radius: 8px;
    }

    &.banner-slim {
        padding: 0.3rem 0;
    }

    &.banner-prefooter {
        p {
            display: none;
            margin: 0;
        }
    }
}

#cms-thirdstep-hide-payments-banner {
    margin-bottom: 0;
}

$step-TYP: 4;

@if $isTc {
    $step-TYP: 5;
}

.checkout-step-#{$step-TYP} {
    #cms-banner-new-client,
    #cms-banner-reorder,
    #cms-thirdstep-hide-payments-banner {
        display: none;
    }
}
