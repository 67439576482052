.tooltip {
    z-index: 99;

    .arrow {
        width: 19px;
        height: 16px;
        display: none;

        &::before {
            border: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='16' viewBox='0 0 19 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.04.418 14.99 14.978c1.29 1.29 3.495.377 3.495-1.446V.418H.041z' fill='%23162056' fill-rule='evenodd'/%3E%3C/svg%3E");
            width: 100%;
            height: 100%;
            margin-top: 8px;
            background-repeat: no-repeat;
        }
    }
}

.bs-tooltip-top {
    margin-bottom: 7px;

    .arrow {
        display: block;
    }
}

.tooltip-icon {
    cursor: pointer;
}

.stooltip {
    width: 14px;
    height: 14px;
    display: inline-flex;

    svg {
        width: 100%;
        height: 100%;
    }
}
