/* stylelint-disable */
/*.modal-backdrop {
    @include media-breakpoint-up(lg) {
        z-index: 0;
    }
}*/

/* stylelint-enable */

.page-wrapper {
    &.light {
        .banner-desktop,
        .news,
        .company,
        .pre-info,
        .what-they-say-about-us,
        .review {
            display: none;
        }

        .hero {
            padding: 110px 0;

            @include media-breakpoint-up(sm) {
                padding: 220px 0;
            }

            @include media-breakpoint-up(xl) {
                padding: 52px 0 66px;
            }
        }

        .hero-image-full {
            display: none;
        }

        .hero-image-light {
            display: block;
        }

        .features {
            padding: 67px 0 66px;
        }

        .questions {
            background-color: #f6f6f6;
            padding: 116px 0 66px 0;
        }

        .assistance {
            background-color: #f6f6f6;
        }

        .helpdesk {
            background-color: #f6f6f6;

            .content-inner {
                background-color: white;
            }
        }
    }
}

.text-dark {
    color: #000;
}

.text-green {
    @extend .green-cta-text;
}

.container {
    padding-right: 20px;
    padding-left: 20px;

    @include media-breakpoint-up(xl) {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.banner-mobile {
    background: $blue-gradient;
    width: 100%;
    padding: 15px 0;
    margin-top: 60px;

    .promo-mobile {
        font-size: 12px;
        line-height: 16px;
    }
}

.banner-promo-top-new {
    background: $blue-gradient;
    width: 100%;
    padding: 13px 0 10px;

    .promo-mobile {
        font-size: 12px;
        line-height: 14px;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 16px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 17px;
            line-height: 19px;
        }
    }
}

.banner-mobile-pink {
    background: #ea1f5c;
}

.section-margins {
    margin-top: 60px;
    margin-bottom: 60px;
}

.section-paddings {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.cool-section {
    background-color: $cold-white;
}

.blue-section {
    background: $blue-gradient;
}

.hero-section {
    margin-top: 32px;
    margin-bottom: 32px;
}

.hero {
    padding-bottom: 68%;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 4%;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="1440" height="50"><path d="M719.5 49.2C477.5 49.2 237.1 32.7 0 .1V50h1440V0c-237.4 32.6-478.1 49.2-720.5 49.2z" fill="%23fff"/></svg>');

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    @include media-breakpoint-up(sm) {
        padding: 180px 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 6px 0 40px;
    }

    .hero-image-full {
        display: block;
        object-fit: cover;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        img {
            object-fit: cover;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            @include media-breakpoint-up(lg) {
                object-position: 50% top;
            }
        }
    }

    .hero-image-light {
        object-fit: cover;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: none;

        img {
            object-fit: cover;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    }

    &-content {
        position: relative;
        z-index: 1;
        display: inline-block;
        display: none;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            margin-top: 50px;
            margin-bottom: 60px;
        }

        @include media-breakpoint-up(xl) {
            display: block;
            margin-top: 80px;
            margin-bottom: 100px;
        }

        .logo {
            margin-bottom: 30px;
        }

        h1 {
            margin-bottom: 16px;
        }

        img {
            height: 67px;
            width: 231px;
            @if $isTc {
                height: 67px;
            }
        }

        .hero-content-intro {
            display: block;
            max-width: 650px;
        }

        &-border {
            position: relative;
            width: 100%;
            padding: 20px 15px 20px 15px;

            @include media-breakpoint-up(lg) {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                width: 45%;
                padding: 24px 80px 24px 0;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: -50vw;
                right: 0;
                bottom: 0;
                background: $blue-gradient;
                z-index: -1;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                @include media-breakpoint-up(lg) {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }

            p {
                @extend .body-1-text;
            }
        }

        .h2 {
            font-size: 27px;
        }

        .h4 {
            max-width: 650px;
        }
    }
}

.hero-content-border-pink {
    &::before {
        background: #ea1f5c;
    }
}

.banner-desktop {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    .hero-content-banner-desktop {
        .logo {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        .hero-button {
            text-align: center;

            a {
                height: 55px;
                line-height: 55px;

                @if $isTc {
                    width: 90%;
                }
            }
        }
    }
}

.pre-header {
    padding: 18px 0;
    display: none;

    .hero-button {
        a {
            height: 44px;
            width: 204px;
        }
    }
}

.news {
    .item-news {
        margin-bottom: 41px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
        }
    }

    .block-news {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        height: 100%;

        > div {
            margin-bottom: 25px;

            @include media-breakpoint-up(xl) {
                min-height: 46px;
                margin-bottom: 17px;
            }
        }
    }
}

.company {
    @include media-breakpoint-up(xl) {
        .container {
            width: 1200px !important;
        }
    }

    #logo-wall .swiper-wrapper {
        transition-timing-function: linear;

        .swiper-slide {
            width: auto;

            img {
                height: auto;
            }
        }
    }
}

.features {
    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        @include media-breakpoint-up(xl) {
            justify-content: flex-end;
        }

        .pane {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 400px;
            }

            &:nth-child(1) {
                @include media-breakpoint-up(lg) {
                    margin-right: 36px;
                }
            }

            &:nth-child(2) {
                @include media-breakpoint-up(lg) {
                    margin-left: 36px;
                }
            }

            .pane-content {
                position: relative;
                padding: 1px 0;
                margin-bottom: 29px;
                border-radius: $box-radius-default;
                background: $pane-content-gradient, #fff;
                box-shadow: 0 0 8px 0 rgba(16, 26, 119, 0.12);

                @include media-breakpoint-up(lg) {
                    margin-bottom: 25px;
                }

                &.is-active {
                    .pane-trs,
                    .pane-tr,
                    .pane-paper,
                    .pane-app {
                        .is-show {
                            display: block;
                            max-height: 20000px;
                        }

                        .is-hide {
                            display: block;
                            max-height: 20000px;
                        }
                    }
                }

                &.is-visible {
                    .pane-trs,
                    .pane-tr,
                    .pane-paper,
                    .pane-app {
                        .is-show {
                            opacity: 1;
                        }

                        .is-hide {
                            opacity: 1;
                            padding: 10px 0;
                        }
                    }
                }

                .pane-trs,
                .pane-tr,
                .pane-paper,
                .pane-app {
                    .is-show {
                        transition: all 0.4s ease-in-out;
                        max-height: 0;
                        display: none;
                        opacity: 0;
                    }

                    .is-hide {
                        transition: all 0.4s ease-in-out;
                        max-height: 0;
                        display: none;
                        opacity: 0;
                        padding: 0;
                    }
                }
            }

            .title,
            .subtitle {
                padding: 20px 0;
            }

            .title {
                @extend .body-1-text-light;

                @include media-breakpoint-down(md) {
                    @if $isTc {
                        font-size: 14px;
                    }
                }

                @include media-breakpoint-up(lg) {
                    width: 100%;
                    text-align: center;
                }
            }

            .subtitle {
                @extend .body-1-text-bold;

                display: none;
                position: absolute;
                right: calc(100% + 35px);
                width: 238px;

                @include media-breakpoint-down(md) {
                    display: block !important;
                }

                @include media-breakpoint-up(md) {
                    margin-right: 70px;
                }

                @include media-breakpoint-up(xl) {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;

                    @if $isTc {
                        justify-content: end;
                    }
                }

                > svg {
                    @include media-breakpoint-up(xl) {
                        display: none;
                    }
                }

                .tooltip-mobile {
                    display: inline-block;
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;

                    @include media-breakpoint-up(xl) {
                        display: none;
                    }

                    > button {
                        margin-right: 10px;
                    }
                }

                .tooltip-info {
                    display: none;
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;

                    @include media-breakpoint-up(xl) {
                        display: flex;
                    }
                }
            }

            .tab-img {
                display: block;
                position: relative;
                padding-bottom: 72%;
                margin: 30px 10px;

                @include media-breakpoint-up(sm) {
                    margin: 30px 20px;
                }

                img {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    max-width: 100%;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .text-green {
                    display: block;
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
            }

            .tab-header,
            .tab-cta,
            .tab-body {
                padding: 0 31px;
                margin: 30px auto;

                @include media-breakpoint-up(lg) {
                    padding: 0 53px;
                }
            }

            .tab-header {
                display: flex;
                flex-direction: column;
                position: relative;
                text-align: center;

                @include media-breakpoint-up(lg) {
                    min-height: 210px;
                }
            }

            .tab-cta {
                text-align: center;

                a {
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                .now {
                    text-transform: uppercase;
                    font-size: inherit;

                    @include media-breakpoint-up(xl) {
                        text-transform: lowercase;
                    }
                }
            }

            .tab-body {
                .pane-trs,
                .pane-app,
                .pane-tr,
                .pane-paper {
                    .title {
                        @include media-breakpoint-down(md) {
                            width: 40%;
                        }
                    }

                    .subtitle {
                        @include media-breakpoint-down(md) {
                            position: static;
                            width: 60%;
                            padding-right: 30px;
                        }
                    }
                }
            }

            .toggle,
            .toggle-new {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                color: #162056;
                font-size: 15px;

                &.mobile {
                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }

                .more,
                .less {
                    cursor: pointer;
                    z-index: 10;
                }

                .less {
                    display: none;
                }

                &.open {
                    .more {
                        display: none;
                    }

                    .less {
                        display: inline-block;
                    }

                    svg {
                        &.less {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .line-spacing {
            height: 60px;
        }
    }
}

.pre-info {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: $box-radius-default;
    background: $blue-gradient;

    @include media-breakpoint-up(lg) {
        padding: 60px 0;
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }

    a {
        line-height: 55px;
        height: 55px;
        padding: 0 58px;

        @if $isTc {
            font-size: 17px;
        }
    }
}

.review {
    overflow: hidden;

    #tns1-iw {
        @include media-breakpoint-down(sm) {
            margin: 0 35px 0 0 !important;
        }
    }

    .tns-nav {
        text-align: right;
        margin-top: 30px;
        padding-right: 15px;

        button {
            margin-right: 8px;
            padding: 0;
            appearance: none;
            border: 0;
            background: 0 0;
            transition: all 300ms ease;

            &::before {
                content: "";
                display: inline-block;
                height: 11px;
                width: 11px;
                background: #fff;
                box-shadow: none;
                border-radius: 20px;
                border: 1px solid $primary;
                transition: all 300ms ease;
                cursor: pointer;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.tns-nav-active {
                &::before {
                    background-color: $primary;
                    width: 30px;
                }
            }
        }
    }

    .review-title {
        font-size: 22px;
        line-height: 30px;

        @include media-breakpoint-up(lg) {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .review-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        @include media-breakpoint-up(lg) {
            font-size: 15px;
            line-height: 21px;
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    user-select: none;
}

.what-they-say-about-us {
    overflow-x: hidden;

    .container {
        position: relative;

        .row {
            overflow-x: hidden;

            @include media-breakpoint-down(lg) {
                width: 100%;
                margin: 0 auto;
            }

            @include media-breakpoint-down(lg) {
                .swiper-button-next::after,
                .swiper-button-prev::after {
                    font-size: 20px;
                }
            }

            .swiper-button-next {
                width: max-content;
                top: 60%;

                &::after {
                    content: none;
                }

                @include media-breakpoint-up(xxl) {
                    right: -100px;
                    top: 56%;
                }
            }

            .swiper-button-prev {
                width: max-content;
                transform: rotate(180deg);
                top: 60%;

                @include media-breakpoint-up(xxl) {
                    left: -100px;
                    top: 56%;
                }

                &::after {
                    content: none;
                }
            }
        }

        .swiper {
            width: 100%;
            height: 100%;
            position: relative;
            padding-bottom: 5rem;

            @include media-breakpoint-down(lg) {
                padding-bottom: 0;
            }

            .swiper-pagination {
                @include media-breakpoint-down(lg) {
                    position: initial;
                    padding-top: 1rem;
                }

                .swiper-pagination-bullet {
                    margin-left: 5px;
                    width: 11px;
                    background: #fff;
                    border: 1px solid $blue-gradient-base;
                    height: 11px;
                    display: inline-block;
                    border-radius: 50%;
                    opacity: 1;
                }

                .swiper-pagination-bullet-active {
                    width: 25px;
                    border: 0;
                    border-radius: 50px;
                    background: $blue-gradient;
                }
            }
        }

        .swiper-slide {
            font-size: 18px;
            background: transparent;
            height: auto;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.questions {
    @extend .box-spacing-default;

    width: 100%;

    .questions-card {
        border: none;

        + .card {
            margin-top: 2px;
        }

        .questions-card-header {
            background-color: transparent;
            margin-top: 2px;
            border: none;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0 32px 0;
                text-decoration: none;
                color: black;
                font-weight: normal;

                .text {
                    padding-right: 70px;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: $font-weight-bold;

                    @include media-breakpoint-up(lg) {
                        padding-right: 0;
                        font-size: 18px;
                        line-height: 28px;
                    }
                }

                .icon {
                    .plus {
                        display: none;
                    }

                    .minus {
                        display: block;
                    }
                }

                &.collapsed {
                    .text {
                        font-weight: $font-weight-light;
                    }

                    .icon {
                        .plus {
                            display: block;
                        }

                        .minus {
                            display: none;
                        }
                    }
                }
            }
        }

        &:first-child {
            .questions-card-header {
                .border-top {
                    border-top: 0 !important;
                }
            }
        }

        .questions-card-body {
            @extend .body-2-text-light;

            padding: 20px 0;

            .video-toggle {
                text-decoration: underline;
            }

            p:last-child,
            ul:last-child {
                margin-bottom: 0;
            }

            &.tr-questions-card-body {
                font-weight: $font-weight-default;
                max-width: 690px;
            }
        }
    }
}

.tr-questions-card-body {
    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-bottom: 24px;
    }

    p {
        font-weight: $font-weight-default;
        margin-bottom: 24px;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 24px;

        li::marker {
            color: #162056;
        }
    }
}

.faq-step {
    display: block;
    position: relative;
    padding-left: 36px;
    padding-bottom: 20px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: 4px;
        bottom: -3px;
        left: 10px;
        width: 1px;
        background: #182a64;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.faq-step-number {
    display: block;
    position: absolute;
    z-index: 2;
    top: 3px;
    left: 0;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    background: radial-gradient(#094c9c 0%, #323d8f 53.13%, #182a64 100%);
}

.faq-cta {
    display: inline-block;
    color: #162056;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.15px;
    padding: 8px 16px;
    border: 1px solid #162056;
    border-radius: 20px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;
    margin-bottom: 20px;

    svg {
        margin-top: -2px;
        margin-left: 8px;
    }

    &:hover,
    &:focus {
        color: #162056;
        text-decoration: none;
    }
}

.cms-home .page-header .hp-b2b {
    display: flex;
    flex: auto;
}

.hide-for-small {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.subtitle-tc {
    font-weight: 600 !important;
    justify-content: left !important;
    align-items: baseline !important;
}

.helpdesk {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 31px 0;
}

.block-review {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    position: relative;
    background: $color-francia-gradient;
    border-radius: $box-radius-default;
    width: 300px;
    padding: 25px 15px;
    height: 100%;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: $box-radius-default - 2px;
        background-color: $white;
    }

    > * {
        z-index: 1;
    }

    h3,
    .h3 {
        font-weight: $font-weight-medium;
    }

    @include media-breakpoint-up(md) {
        padding: 35px 25px;
    }

    .paragraph {
        line-height: 25px;
    }

    > div {
        text-align: left;
    }

    .p:first-child {
        min-height: 125px;
    }

    .p:last-child {
        min-height: 40px;
    }
}

.mtop-auto {
    margin-top: auto;
}

.modal-brand-logos {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.modal-brand-logo {
    display: block;

    img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 250px;
        margin: 0 auto;
    }
}

.features-container {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
    }
}

.featured-swiper-slide {
    padding: 40px 32px;

    h4,
    img {
        margin-bottom: 24px;
    }

    p {
        margin-bottom: 0;
    }
}

.featured-cta-container {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 56px;
    }
}

.home-payment-section {
    @include media-breakpoint-up(lg) {
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .container-fluid {
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.home-payment-container {
    display: block;
    position: relative;
    padding: 40px 20px;
    background: $color-francia-gradient-light;

    @include media-breakpoint-up(lg) {
        padding: 32px 0;
        border-radius: 12px;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}

.home-payment-contents {
    padding: 0 20px;

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 64px;
        width: 50%;
    }
}

.home-payment-bullet-images {
    display: none;
    position: absolute;
    top: -70px;
    bottom: -70px;
    right: -26px;
    width: 50%;
    overflow: visible;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.home-payment-bullet-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    object-fit: contain;

    &.active {
        opacity: 1;
    }
}

.home-payment-bullets-list {
    display: none;
    margin-top: 32px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    li {
        display: block;
        position: relative;
        overflow: visible;

        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 5px;
            bottom: -4px;
            left: 9px;
            width: 2px;
            background-color: $blue-gradient-base;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    .home-payment-bullet {
        display: block;
        position: relative;
        padding-bottom: 32px;
        padding-left: 32px;
        color: $dark-blue;
        text-decoration: none;

        &.active {
            font-weight: $font-weight-bold;
            color: $dark-blue;
            text-decoration: none;
        }

        &:hover,
        &:focus {
            color: $dark-blue;
            text-decoration: none;
        }

        .bullet-step-number {
            display: block;
            position: absolute;
            z-index: 2;
            top: 3px;
            left: 0;
        }
    }
}

.home-payment-swiper {
    display: block;
    max-width: 450px;
    margin: 24px auto 48px;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.home-payment-swiper-slide-content {
    display: inline-block;
    position: relative;
    max-width: 250px;
    padding-left: 35px;
    margin: 24px auto;

    .bullet-step-number {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.home-expert-box {
    display: block;
    position: relative;
    padding: 42px 25px;
    border-radius: 16px;
    background: $dark-blue;
    color: #fff;
    margin-top: 105px;

    @include media-breakpoint-up(xl) {
        padding-left: 36px;
        padding-right: 36px;
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 78px;
    }

    @include media-breakpoint-up(xxl) {
        padding-left: 72px;
        padding-right: 72px;
        margin-left: -30px;
        margin-right: -30px;
    }
}

.home-expert-subtitle {
    @include media-breakpoint-up(xl) {
        padding-right: 36px;
    }
    @include media-breakpoint-up(xxl) {
        padding-right: 78px;
    }
}

.home-expert-image {
    display: block;
    position: relative;
    margin-top: -23%;
    margin-bottom: 39px;
    text-align: center;

    @include media-breakpoint-up(sm) {
        margin-top: -18%;
    }

    @include media-breakpoint-up(md) {
        margin-top: -14%;
    }

    @include media-breakpoint-up(lg) {
        margin-top: -12%;
    }

    @include media-breakpoint-up(xl) {
        position: absolute;
        top: -78px;
        margin-top: 0;
        margin-bottom: 0;
        right: 59px;
        left: 50%;
    }
}

.home-expert-text {
    @include media-breakpoint-up(xl) {
        width: 50%;
    }
}

.swiper-home-style {
    @include media-breakpoint-down(lg) {
        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-size: 20px;
        }
    }

    .swiper-button-next {
        width: max-content;
        top: 60%;
        right: 0;

        &::after {
            content: none;
        }

        @include media-breakpoint-up(xxl) {
            right: -100px;
            top: 56%;
        }
    }

    .swiper-button-prev {
        width: max-content;
        transform: rotate(180deg);
        top: 60%;
        left: 0;

        @include media-breakpoint-up(xxl) {
            left: -100px;
            top: 56%;
        }

        &::after {
            content: none;
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-pagination {
        left: 20%;

        @include media-breakpoint-down(lg) {
            position: initial;
            padding-top: 1rem;
        }

        .swiper-pagination-bullet {
            margin-left: 5px;
            width: 11px;
            background: #fff;
            border: 1px solid $blue-gradient-base;
            height: 11px;
            display: inline-block;
            border-radius: 50%;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            width: 25px;
            border: 0;
            border-radius: 50px;
            background: $blue-gradient;
        }
    }
}

.section-expert {
    margin-top: 100px;
    margin-bottom: 100px;

    @include media-breakpoint-up(lg) {
        margin-top: 150px;
        margin-bottom: 108px;
    }
}

.tr-static-header {
    padding: 11px 0;

    .logo {
        img {
            height: 30px;
            width: 115px;

            @if $isTc {
                width: 128px;
            }

            @include media-breakpoint-up(lg) {
                height: auto;
                width: 209px;
            }

            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
    }

    .buy {
        > a {
            height: 30px;
            width: 130px;
            font-size: 12px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                height: 44px;
                width: auto;
                border-radius: 40px;
                padding: 0 40px;
                line-height: 44px;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
            }
        }
    }
}

.featured-swiper {
    max-width: 550px;

    .swiper-button-next,
    .swiper-button-prev {
        top: 40%;

        @include media-breakpoint-up(md) {
            top: 50%;
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: none;
    }
}
