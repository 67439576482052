.maintenance-section {
    margin: 60px 0;
}

.maintenance-header {
    margin-bottom: 10px;
    padding: 20px 0;
}

.maintenance {
    .form-check-input {
        position: relative;
    }

    .form-group {
        max-width: 285px;
    }

    .contact-form {
        .ask {
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            color: black;
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                font-size: 17px;
                line-height: 26px;
                margin-bottom: 20px;
            }
        }

        .contact {
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            color: black;
            margin-bottom: 19px;

            @include media-breakpoint-up(lg) {
                font-size: 17px;
                line-height: 26px;
                margin-bottom: 20px;
            }
        }

        .name {
            margin-bottom: 20px;
        }

        .phone {
            margin-bottom: 31px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
            }
        }

        .phone-btn {
            @extend .body-1-text-bold;

            padding: 10px 14px;
            display: block;
            color: #162056;
            background-color: $cold-white;
            text-decoration: none;
            border-radius: $box-radius-default;
            max-width: 331px;

            svg {
                margin-right: 10px;
            }
        }

        .privacy {
            margin-bottom: 28px;
            font-size: 12px;
            line-height: 19px;
            color: black;

            @include media-breakpoint-up(lg) {
                margin-bottom: 12px;
                font-size: 13px;
                line-height: 21px;
            }
        }

        .radio {
            display: flex;
            margin-bottom: 27px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 33px;
            }

            .form-check-input {
                cursor: pointer;
            }

            .form-check-inline {
                label {
                    font-size: 14px;
                    margin-bottom: 0;
                    font-weight: 500;
                }
            }

            .form-check-inline:first-child {
                margin-right: 16px;

                @include media-breakpoint-up(lg) {
                    margin-right: 22px;
                }
            }

            .label-desktop {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: flex;
                }
            }

            .label-mobile {
                display: flex;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            .form-check-label {
                top: 0;
            }
        }

        .active-service {
            font-size: 12px;
            font-weight: 300;
            display: inline-block;
            margin-left: 10px;
        }
    }
}
