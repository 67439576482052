#cms-intro-trs,
#cms-intro-tr {
    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
    }

    p {
        font-size: 14px;
        line-height: 21px;

        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 30px;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    strong {
        display: inline-block;
        color: $primary-color;
        margin-right: 4px;
    }
}
