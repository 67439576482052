// 00. Buttons 🟢
// 00. Links 🟢

// 00. Buttons
.btn {
    @extend .label-text-bold;

    line-height: 44px;
    height: 45px;
    border-radius: 40px;
    padding: 0 40px;
    color: $white;

    span {
        line-height: inherit;
        letter-spacing: 0.15px;
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }

    &:hover,
    &:focus {
        color: $white;
        box-shadow: none;
    }

    &.only-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        padding: 0;
        // border-radius: 50%;
    }

    &.large {
        @extend .cta-text;

        line-height: 55px;
        height: 55px;
        padding: 0 50px;

        @include media-breakpoint-up(lg) {
            padding: 0 70px;
        }

        &.xl {
            padding: 0 70px;
        }
    }

    &.btn-success,
    &.btn-green,
    &.btn-blue,
    &.btn-blue-solid {
        .loading-icon {
            color: white;
        }
    }

    svg {
        width: 26px;
        height: 26px;
    }
}

.proceed-button {
    width: 100%;
    max-width: 166px;

    @include media-breakpoint-up(lg) {
        max-width: 214px;
    }

    &:disabled {
        &.loading {
            opacity: 1;
        }
    }
}

.reset-btn {
    appearance: none;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
}

a.btn {
    display: inline-block;
    vertical-align: middle;
}

.btn-blue,
.btn-dark-blue {
    background: $cta-linear-gradient;
    color: $white;

    &:hover,
    &:focus {
        background: $cta-linear-gradient-darker;
    }

    &.btn-outline {
        background: #fff;
        border: 1px solid $primary-color;
        color: $primary-color;
        transition: none;

        &:hover,
        &:focus {
            color: #fff;
            background: $cta-linear-gradient-darker;
        }
    }
}

.btn-blue-solid {
    background-color: $primary-color;
    color: $white;

    &:hover,
    &:focus {
        background-color: #101845;
    }

    &.btn-outline {
        background-color: #fff;
        border: 1px solid $primary-color;
        color: $primary-color;
    }
}

.btn-success,
.btn-green {
    background: $green-cta-gradient;
    color: $white;

    &:hover,
    &:focus {
        background: $green-cta-gradient-darker;
    }
}

.btn-dark-blue,
.btn-success {
    border: 0;
}

.btn-white {
    background-color: $white;
    color: $primary-color;

    &:hover,
    &:focus {
        background-color: $white;
        color: $primary-color;
    }
}

.btn-red {
    background-color: $red;
    color: $white;

    &:hover,
    &:focus {
        background-color: #ca2016;
    }
}

// 00. Links
.link {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    appearance: none;
    border: 0;
    background-color: transparent;
    font-weight: $font-weight-medium;
    letter-spacing: 0.15px;

    span {
        line-height: inherit;
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &.lite-link {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: $primary-color;
        text-decoration: underline;
    }

    svg {
        width: 14px;
        height: 14px;
    }

    span + svg {
        margin-left: 20px;
    }
}

.link-blue {
    color: $primary-color;

    &:hover,
    &:focus {
        color: #101845;
    }
}

.link-green {
    color: $green;

    &:hover,
    &:focus {
        color: #035f3d;
    }
}

.link-red {
    color: $red;

    &:hover,
    &:focus {
        color: #ca2016;
    }
}

.link-underline {
    color: #444;
    text-decoration: underline !important;

    &:hover,
    &:focus {
        text-decoration: underline !important;
    }
}
