// stylelint-disable
.chat {
    .text-dark-blue {
        font-size: 18px;
        font-weight: $font-weight-bold;
    }
}

.cms-home {
    .chat {
        display: block;
    }

    .banner-col {
        display: none;
    }
}

.cms-no-route {
    .chat {
        display: none !important;
        @if $isTc {
            display: block !important;

            @include media-breakpoint-up(lg) {
                display: flex !important;
            }

            @include media-breakpoint-up(lg) {
                button.live-faq {
                    display: block !important;
                }
            }
        }
    }

    .banner-col {
        display: block;

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        @if $isTc {
            display: none;
        }
    }
}

.edenred-courtesypage-index {
    .chat {
        display: none !important;

        @if $isTc {
            display: block !important;

            @include media-breakpoint-up(lg) {
                display: flex !important;
            }

            @include media-breakpoint-down(md) {
                button.live-faq {
                    display: none !important;
                }
            }
        }
    }

    .banner-col {
        display: block;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
}

.edenred-courtesypage-result {
    .chat {
        display: none !important;
    }

    .banner-col {
        display: block;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
}

.checkout-step-1 {
    .chat {
        display: block !important;

        @include media-breakpoint-up(lg) {
            display: flex !important;
        }

        > button.live-faq {
            display: flex !important;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        > button.liveagent-chat-start {
            @if $isTr {
                display: none !important;
            }
            @include media-breakpoint-up(lg) {
                display: flex !important;
            }
        }
    }
}

#assistance-section {
    background-color: #f1f7ff;
}

.assistance {
    background-color: #f1f7ff;
    padding: 33px 0 21px 0;

    @include media-breakpoint-up(lg) {
        padding: 32px 0 34px 0;
    }

    .container {
        @include media-breakpoint-down(md) {
            text-align: center;
        }

        a {
            svg {
                width: 1.3em;
                height: 1.3em;
            }
        }
    }

    .chat {
        display: none;
        margin-bottom: 33px;

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        > button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 230px;
            padding: 0 26px;
            margin: auto;

            @include media-breakpoint-up(lg) {
                margin: initial;
            }

            svg {
                margin-right: 2px;
            }
        }

        > button.live-faq {
            //display: none;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .phone-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        line-height: 16px;

        @include media-breakpoint-up(lg) {
            align-items: flex-end;
        }

        .number-green {
            font-weight: $font-weight-bold;
            margin-top: 0 !important;
            margin-bottom: 5px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        .number-desktop {
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: flex-end;
            }

            .number {
                @include make-gradient-text($cta-linear-gradient);

                @extend .body-1-text-bold;
            }

            .hours {
                font-size: 11px;
                font-weight: 600;
                @if $isTc {
                    display: none;
                }
            }
        }

        .number-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            min-width: 210px;
            padding: 0;

            @include media-breakpoint-up(lg) {
                display: none;
                margin-bottom: 0;
            }

            span {
                font-size: 18px;
            }

            .hours-desktop {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0 29px;
                color: #fff;
                width: 100%;
                min-width: 210px;
                font-size: 20px;

                svg {
                    height: 16px;
                    width: 16px;
                    margin-right: 10px;
                }
            }
        }

        .hours-mobile {
            display: block;
            font-weight: bold;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    .banner-col {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 0;
        }

        @if $isTc {
            display: none;
        }
    }

    .modal-body {
        .questions {
            color: black;
            padding: 0;
        }
    }
}
