.bubble {
    @extend .body-2-text-light;

    background-color: $cold-white;
    border-radius: $box-radius-default;
    padding: 10px 20px;
    color: #000;
    display: flex;
    align-items: center;
    line-height: 23px;

    @include media-breakpoint-up(lg) {
        height: 100%;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 8px;
    }

    .bubble-text {
        padding-left: 25px;
    }

    svg {
        width: 16px;
        height: 16px;
        fill: #162056;
    }
}

@include media-breakpoint-down(md) {
    .TR {
        .col.TR {
            display: block;
        }

        .col.TRS {
            display: none;
        }
    }

    .TRS {
        .col.TRS {
            display: block;
        }

        .col.TR {
            display: none;
        }
    }
}
@include media-breakpoint-up(lg) {
    .TR {
        .col.TR {
            opacity: 1;
        }

        .col.TRS {
            opacity: 0.7;
        }
    }

    .TRS {
        .col.TRS {
            opacity: 1;
        }

        .col.TR {
            opacity: 0.7;
        }
    }
}

.bubble-sidebar {
    @extend .body-2-text-light;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include media-breakpoint-down(md) {
        width: 100%;
        padding: 5px 0;
        border: 2px solid #f5f8f5;
        border-bottom: 0;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    @include media-breakpoint-up(lg) {
        text-align: right;
        justify-content: flex-end;
    }
}

.minimap-block {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        background-color: #f5f5f6;
        flex-direction: row;
    }

    img {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 256px;
        }
    }

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 2;

        @include media-breakpoint-up(lg) {
            padding: 20px;
            padding-left: 40px;
            flex-direction: row;
        }
    }

    span {
        font-size: 18px;
        font-weight: 100;
        margin: 0;
        padding: 0;
        text-align: center;
        padding-top: 22px;
        display: block;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: calc(100% - 224px);
            padding-top: 0;
            padding-right: 70px;
            text-align: left;
        }
    }

    .hero-button {
        padding: 0;
        width: 224px;

        @include media-breakpoint-down(md) {
            position: static;
            box-shadow: none;
            width: 100%;
        }
    }

    .btn {
        padding-left: 25px;
        padding-right: 25px;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }
}

#cms-table {
    .block-section {
        margin-bottom: 5px;
    }

    .block-toolbar {
        display: none;
    }
}

.block-type-selector {
    .block {
        margin-bottom: 0;
    }
}
