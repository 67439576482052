.page-header {
    background: white;
    padding: 15px 0;

    @include media-breakpoint-up(lg) {
        padding: 18px 0;
    }

    .logo {
        img {
            height: 30px;
            width: 115px;

            @if $isTc {
                width: 128px;
            }

            @include media-breakpoint-up(lg) {
                height: auto;
                width: 209px;
            }

            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
    }

    .buy {
        display: none;

        > a {
            height: 30px;
            width: 130px;
            font-size: 12px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                height: 44px;
                width: auto;
                border-radius: 40px;
                padding: 0 40px;
                line-height: 44px;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
            }
        }
    }

    .help {
        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 19px;
            padding: 0;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
                line-height: 25px;
            }
        }
    }

    .container-header {
        position: relative;
        background-color: #fff;
    }
}

.cms-home {
    .page-header {
        display: block;
        width: 100%;
        z-index: 100;
        background: white;
        padding: 15px 0;
        will-change: opacity, transform;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateY(-80px);
        opacity: 0;

        @include media-breakpoint-up(lg) {
            display: block;
            padding: 18px 0;
        }

        &.is-visible {
            transform: translateY(0);
            opacity: 1;
            transition: transform 0.8s ease, opacity 0.2s ease;
        }

        .help {
            display: none;
        }

        .buy {
            display: block;
        }
    }
}
