:focus {
    outline: none !important;
}

html[data-whatintent="keyboard"] {
    *:focus,
    button:focus {
        outline: -webkit-focus-ring-color auto 5px !important;
        outline-offset: 3px;
    }

    .form-bool {
        &:focus-within .checkbox-input,
        &:focus-within .radio-input {
            outline: -webkit-focus-ring-color auto 1px;
            outline-offset: 2px;
        }
    }
}
