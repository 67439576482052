.modal {
    padding: 0 !important;

    .modal-dialog {
        display: flex;
        align-items: center;
        height: 100vh;
        min-height: subtract(100%, 3.5rem);
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        @if $isTc {
            height: auto;
        }

        @include media-breakpoint-up(md) {
            align-items: center;
            max-width: 60%;
        }
    }

    .modal-content {
        overflow: auto;

        @include media-breakpoint-up(md) {
            max-height: 90vh;
        }
    }

    .modal-header {
        border-bottom: none;
        justify-content: flex-end;
        padding: 25px;
        padding-right: 40px;
        padding-bottom: 0;

        .close {
            display: inline-block;
            position: absolute;
            width: 20px;
            height: 20px;
            cursor: pointer;
            z-index: 100;
            padding: 0;
            margin: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12.43 11.298 9.934-9.933a.8.8 0 0 0-1.132-1.13l-9.933 9.932L1.366.234A.8.8 0 1 0 .235 1.365l20.997 20.997a.8.8 0 1 0 1.132-1.13l-9.933-9.934zM.236 21.232a.799.799 0 1 0 1.131 1.13l9.925-9.924-1.131-1.132-9.925 9.926z' fill='%23162056' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-size: cover;
            top: 30px;
            right: 30px;

            span:not(.sr-only) {
                display: none;
            }
        }
    }

    .modal-title {
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            margin-right: 3rem;
        }
    }

    .modal-body {
        padding: 30px;
        padding-top: 30px;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-up(md) {
            padding: 30px;
            padding-bottom: 70px;
            padding-top: 0;
        }
    }

    &.squared {
        .modal-dialog {
            @include media-breakpoint-up(md) {
                max-width: 55%;
            }
        }

        .modal-content {
            border-radius: 0;
        }

        .modal-body {
            overflow-wrap: anywhere;
            justify-content: start;

            @include media-breakpoint-up(md) {
                padding-top: 30px;
            }
        }
    }
}

.modal-video {
    background: rgba(0, 0, 0, 0.7);

    .modal-dialog {
        justify-content: center;
        max-width: 100%;

        .modal-content {
            background-color: transparent;
            border: 0px;
            width: auto;

            .modal-header {
                padding: 0;
                align-items: end;
            }

            .modal-body {
                padding: 0;

                video {
                    max-width: 100%;
                }
            }

            .close::before,
            .close::after {
                background-color: #fff;
                content: "";
                display: block;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                transform-origin: center center;
            }

            .close {
                background-image: none;
                width: 40px;
                height: 40px;

                &::before {
                    height: 2px;
                    width: 60%;
                }

                &::after {
                    height: 60%;
                    width: 2px;
                }

                &:hover,
                &:active {
                    background-color: rgba(10, 10, 10, 0.3);
                    border-radius: 100px;
                }
            }
        }
    }
}

.modal-video,
.btn-modal-video {
    @include media-breakpoint-down(md) {
        display: none;
        visibility: hidden;
    }
}
