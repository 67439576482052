.loading-msg {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #444;
    padding: 80px 70px 80px 70px;

    @include media-breakpoint-up(lg) {
        padding: 200px 0;
    }

    &.has-title {
        > div {
            margin-top: 50px;

            @include media-breakpoint-up(lg) {
                margin-top: 20px;
            }
        }
    }

    span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        font-size: 80px;
        display: inline-flex;
        height: 20px;
        align-items: center;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }

        &:nth-child(4) {
            animation-delay: 0.6s;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

.lazy {
    opacity: 0;
    transition: opacity 0.1s ease;

    &.loaded {
        opacity: 1;
    }
}
