footer {
    padding: 40px 0 50px 0;
    background-color: $darkBlue;

    .footer-mb {
        margin-bottom: 20px;
    }

    .footer-logo {
        padding-bottom: 35px;
    }

    .reserved {
        font-size: 14px;
    }

    .divider {
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 27px;
        width: 100vw;

        @include media-breakpoint-up(lg) {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 38px;
        }
    }

    .divider-alt {
        margin-top: 24px;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-top: 38px;
        }
    }

    .opacity {
        color: #858aa6;
        font-size: 12px;
        line-height: 15px;
    }

    .iva {
        margin-bottom: 28px;
        font-size: 12px;
        line-height: 15px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 45px;
        }
    }

    .copyright {
        font-size: 12px;
    }

    .footer-logos-tr {
        max-width: 320px;
        margin-bottom: 18px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        img {
            border: 1px solid #6d7787;
            border-radius: 4px;
            max-width: 90px;
            height: auto;
        }
    }

    .payments {
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
        }

        > div {
            max-width: 320px;
            margin-bottom: 18px;
        }

        .payments-logo {
            > div {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                img {
                    max-width: 33.33%;
                }
            }
        }
    }

    .plus {
        margin-bottom: 64px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-size: 10px;
            }
        }
    }

    .tr-link {
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            margin-top: 32px;
        }
    }
}

.web-app {
    .banner-col {
        display: none;
    }
}
