.phone-input-group {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: $input-border-width solid $input-border-color;

    &:focus-within {
        border-color: #000;
    }

    .form-group {
        margin-bottom: 0;
    }

    .phone-input {
        border: 0;

        &:focus {
            border: 0;
        }
    }

    .phone-prefix-selector {
        margin-left: 10px;
        border-radius: 8px;
        border: $input-border-width solid $input-border-color;
        padding: 4px 8px;

        .prefix-flag {
            display: flex;
            align-items: center;

            img {
                height: 12px;
            }
        }

        .prefix-value {
            margin-left: 4px;
            line-height: 1;
        }
    }

    &:not(:focus-within).is-invalid {
        border: 1px solid $danger;
    }
}

.phone-country-selector {
    z-index: 1;
}

.country-list-wrapper {
    z-index: 1;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    background: white;
    border-radius: 8px;
    border: $input-border-width solid $input-border-color;
    width: 450px;
    height: 250px;
    overflow: auto;

    .country-list {
        list-style: none;
        padding-left: 0;
    }

    .country-list-item {
        padding: 5px 10px 5px 15px;
    }

    .country-button {
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }
}
