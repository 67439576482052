#helpdesk {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .toggle-content {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d52b1e;
        border-radius: 100%;
        z-index: 1;
        position: relative;
        cursor: pointer;
        color: white;
    }

    .content {
        height: 70px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 70px;
        margin-right: 0;
        z-index: 0;
        display: flex;
        align-items: center;
        max-width: 70px;
        transform: scale(0);
        overflow: hidden;
        background: rgba(255, 255, 255, 0.8);

        @include media-breakpoint-up(xl) {
            margin-right: 5px;
        }

        > * {
            display: flex;
            align-items: center;
            justify-content: center;

            + * {
                margin-left: 10px;
            }
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        color: #fff;
        height: 70px;
        background-color: #162056;
        border-radius: 70px;
        padding: 0 15px;
        border: 2px solid #fff;

        @include media-breakpoint-up(lg) {
            padding: 0 40px;
        }
    }

    .text {
        height: 70px;
        color: $primary;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 25px;
        z-index: 0;
        display: flex;
        align-items: center;
        max-width: 70px;
        transform: scale(0);
        overflow: hidden;

        > * {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;

            + * {
                margin-left: 10px;
            }
        }
    }

    &.open {
        .content,
        .text {
            animation: helpdesk-slide-content 0.3s cubic-bezier(0.4, 0, 0.2, 1) both;
            transform: scale(1);
        }
    }

    &.closed {
        .content,
        .text {
            animation: helpdesk-slide-content-close 0.3s cubic-bezier(0.4, 0, 0.2, 1) both;
        }
    }

    @keyframes helpdesk-slide-content {
        from {
            max-width: 70px;
            right: 0;
        }

        to {
            max-width: 1000px;
            right: calc(100% + 10px);
        }
    }

    @keyframes helpdesk-slide-content-close {
        0% {
            max-width: 1000px;
            right: calc(100% + 10px);
            transform: scale(1);
        }

        99.99% {
            max-width: 70px;
            right: 0;
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }

    .call-center {
        display: flex;
        align-items: center;
        padding-right: 29px;
        cursor: pointer;

        a:hover {
            text-decoration: none;
        }

        .cc-content {
            display: none;
            font-size: 10px;
            white-space: nowrap;
            margin-left: 16px;
            line-height: 1.3;

            .number {
                margin: 0;
            }
        }

        &.open {
            .cc-content {
                display: block;

                a {
                    color: white;
                    display: flex;
                    flex-direction: column;

                    .number {
                        color: white;
                    }
                }
            }

            + .chat {
                display: none;
            }
        }
    }

    .call-center-info {
        display: flex;
        align-items: center;
        padding-right: 2px;
        padding-left: 8px;
        text-align: end;
        min-width: 90px;

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            padding-right: 8px;
            padding-left: 8px;
            text-align: end;
            min-width: 100px;
        }

        > p {
            font-size: 14px;
            font-weight: 700;
        }

        a:hover {
            text-decoration: none;
        }

        .cc-content {
            display: none;
            font-size: 10px;
            white-space: nowrap;
            margin-left: 16px;
            line-height: 1.3;

            .number {
                margin: 0;
            }
        }

        &.open {
            .cc-content {
                display: block;
            }

            + .chat {
                display: none;
            }
        }
    }
}
