.btn {
    &.btn-dark-blue {
        @include button-variant($darkBlue, $darkBlue);
    }

    &.btn-block {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &[class*="btn-outline"] {
        border-width: 2px;
    }

    &.border-radius-small {
        border-radius: $input-border-radius;
    }
}

button.as-link {
    appearance: none;
    border: 0;
    background: transparent;
    font-weight: 300;

    &.hover-underline {
        &:hover {
            text-decoration: underline;
        }
    }
}

.input-group {
    .btn {
        border-radius: $border-radius;
    }
}

// .form-control {
//     &.is-valid {
//         background: none;
//         border-color: #ced4da;

//         &:focus {
//             border-color: #ced4da;
//             box-shadow: none;
//         }
//     }
// }

.tooltip {
    .tooltip-inner {
        background-color: #162056;
        color: #fff;
        font-weight: 100;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        padding: 12px 19px;

        @include media-breakpoint-up(md) {
            max-width: 300px;
        }
    }

    &.bs-tooltip-top {
        .arrow {
            bottom: 2px;
            margin-left: -7px;
            pointer-events: none;

            &::before {
                border-width: 1rem 0 1rem 1rem;
                border-top-color: #162056;
            }
        }
    }
}

.alert {
    &-danger {
        background-color: $danger;
        color: #fff;
        font-size: 14px;
        border-color: transparent;
    }

    .close {
        color: #fff !important;
        font-weight: 100;
    }
}

.modal {
    &-no-header {
        .close {
            display: inline-block;
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 4;
        }
    }
}
