html {
    font-size: 14px;
    letter-spacing: 0.15px;

    @include media-breakpoint-up(lg) {
        font-size: 15px;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $primary-color;
}

h1,
.h1 {
    @extend .heading-1;
}

h2,
.h2 {
    @extend .heading-2;
}

h3,
.h3 {
    @extend .heading-3;
}

h4,
.h4 {
    @extend .heading-4;
}

h5,
.h5,
h6,
.h6 {
    font-size: 18px;
    line-height: 26px;

    @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 30px;
    }
}

p {
    @extend .body-2-text-light;
}

p,
span {
    &.muted {
        color: $gray;
    }
}

label {
    font-weight: $font-weight-bold;
    font-size: 14px;
    letter-spacing: 0.15px;

    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}

strong,
b {
    font-weight: $font-weight-bold;
}

small,
.small,
.text-small {
    @extend .body-2-text-light;
}

.text-small-2 {
    @extend .caption-text-light;
}

.text-small-3,
.text-small-3-disabled {
    @extend .caption-text-light;
}

.text-small-3-disabled {
    color: rgba(0, 0, 0, 0.5);
}

.font-numerica {
    font-family: $font-edenred-base;
    font-size: 49px;
    line-height: 59px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.5px;

    @include media-breakpoint-up(lg) {
        font-size: 52px;
        line-height: 62px;
    }
}

.text-underline {
    text-decoration: underline !important;
}

.cursor-pointer {
    cursor: pointer;
}

hr {
    &.big {
        border-width: 3px;
        border-color: #eee;
        margin-bottom: 30px;
    }
}

.hr {
    border-bottom: 1px solid $gray;
    color: $black;
    font-size: 14px;
    line-height: 18px;
    font-weight: 100;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-align: right;
}

.blue {
    color: $primary-color;
}

.text-black {
    color: #000;
}

.text-dark {
    color: #444;
}
