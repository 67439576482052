@mixin style-check($top, $left, $w, $h) {
    position: absolute;
    top: #{$top}px;
    left: #{$left}px;
    width: #{$w}px;
    height: #{$h}px;
}

.form-bool {
    position: relative;

    .radio-group.inline & {
        display: inline-block;
        margin-right: 16px;
    }

    label {
        @extend .caption-text-light;

        margin-bottom: 0;
        padding-left: 18px;

        a,
        a:hover {
            color: $black;
            font-weight: 700;
        }
    }

    input[type="checkbox"] {
        opacity: 0;
        margin: 0;
        z-index: 1;

        @include style-check(0, 0, 1, 1);
    }

    input[type="checkbox"] + .checkbox-input {
        width: 12px;
        pointer-events: none;
        height: 12px;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
        border-radius: 4px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='11' height='11' rx='2.5' fill='%23fff' stroke='%23979797'/%3E%3C/svg%3E");
    }

    input[type="checkbox"]:checked + .checkbox-input {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='11' height='11' rx='2.5' fill='%23fff' stroke='%23979797'/%3E%3Crect x='2' y='2' width='8' height='8' rx='2' fill='%23333'/%3E%3C/svg%3E");
    }

    input[type="radio"] {
        opacity: 0;
        margin: 0;
        z-index: 1;

        @include style-check(0, 0, 1, 1);
    }

    input[type="radio"] + .radio-input {
        width: 12px;
        pointer-events: none;
        height: 12px;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='11' height='11' rx='5.5' fill='%23fff' stroke='%23979797'/%3E%3C/svg%3E");
    }

    input[type="radio"]:checked + .radio-input {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.5' y='.5' width='11' height='11' rx='5.5' fill='%23fff' stroke='%23979797'/%3E%3Crect x='2' y='2' width='8' height='8' rx='4' fill='%23333'/%3E%3C/svg%3E");
    }

    &.is-invalid {
        input[type="checkbox"] + .checkbox-input,
        input[type="radio"] + .radio-input {
            box-shadow: inset 0 0 0 1px $danger;
            background-image: none !important;
        }
    }
}

.form-group {
    position: relative;
    overflow: visible;

    &:has(> input[type="text"].is-valid),
    &:has(> input[type="password"].is-valid),
    &:has(> input[type="email"].is-valid),
    &:has(> input[type="number"].is-valid),
    &:has(> input[type="tel"].is-valid),
    &:has(> input[type="url"].is-valid),
    &:has(> input[type="text"].is-invalid),
    &:has(> input[type="password"].is-invalid),
    &:has(> input[type="email"].is-invalid),
    &:has(> input[type="number"].is-invalid),
    &:has(> input[type="tel"].is-invalid),
    &:has(> input[type="url"].is-invalid) {
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 16px;
            right: -12px;
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 10px;
        }
    }

    &:has(> input.is-valid) {
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231BB934' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
        }
    }

    &:has(> input.is-invalid) {
        &::after {
            background-size: 8px;
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23b40303' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'%3E%3C/path%3E%3C/svg%3E");
        }
    }

    label {
        @extend .body-2-text-light;

        position: absolute;
        top: 4px;
        left: 16px;
        margin-bottom: 0;
        transform: translateY(0) scale(0.6);
        transform-origin: left top;
        transition: transform 0.2s ease;
        pointer-events: none;
        width: calc(160% - 36px);
        white-space: nowrap;
        overflow: hidden;
    }

    .loading-icon {
        position: absolute;
        top: 32%;
        right: 10px;
        animation: spin 1.5s linear infinite;
    }

    .eye-icon {
        position: absolute;
        top: 10px;
        right: 25px;
    }

    .dropdown {
        + label {
            display: none;
        }

        .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 45px;
            padding: 14px;
            font-size: 16px;
            font-weight: 300;
            line-height: 1;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 8px;
            transition: none;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cmask id='b' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='14' height='8'%3E%3Cpath d='M14 .315V8H.893V.315H14z' fill='%23fff'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.893 1.115A.798.798 0 0 1 2.258.55l5.189 5.19L12.635.55a.8.8 0 0 1 1.13 1.132l-6.318 6.32-6.32-6.32a.798.798 0 0 1-.234-.566z' fill='%23B0B1B4'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(-180 7 4)' d='M0 0h14v8H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                width: 14px;
                height: 8px;
                vertical-align: middle;
                border: 0;
            }

            &:focus {
                box-shadow: none;
                outline: none;
                border: 1px solid #162056;

                @if $isTc {
                    border: 3px solid #162056;
                }

                &:active {
                    box-shadow: none;
                    border: 1px solid #ced4da;
                    color: #495057;
                    background-color: #fff;
                }
            }
        }

        &.is-invalid {
            .dropdown-toggle {
                border: 1px solid $danger;
            }
        }

        .dropdown-menu {
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            pointer-events: all !important;
        }

        .dropdown-item {
            padding: 5px 14px;
            font-size: 15px;
            cursor: pointer !important;
        }

        &.show {
            .dropdown-toggle {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-color: #162056;
                border-bottom-color: transparent;

                &::after {
                    transform: rotate(180deg);
                }
            }

            .dropdown-menu {
                border-color: #162056;
                border-radius: 8px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: 0;
                top: -3px !important;
            }
        }
    }

    select.form-control {
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cmask id='b' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='14' height='8'%3E%3Cpath d='M14 .315V8H.893V.315H14z' fill='%23fff'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.893 1.115A.798.798 0 0 1 2.258.55l5.189 5.19L12.635.55a.8.8 0 0 1 1.13 1.132l-6.318 6.32-6.32-6.32a.798.798 0 0 1-.234-.566z' fill='%23B0B1B4'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(-180 7 4)' d='M0 0h14v8H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-origin: content-box;
        background-position: right -6px center;
        background-repeat: no-repeat;
        background-size: 14px 8px;
        padding-right: 1.5rem;

        &.is-invalid {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cmask id='b' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='14' height='8'%3E%3Cpath d='M14 .315V8H.893V.315H14z' fill='%23fff'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.893 1.115A.798.798 0 0 1 2.258.55l5.189 5.19L12.635.55a.8.8 0 0 1 1.13 1.132l-6.318 6.32-6.32-6.32a.798.798 0 0 1-.234-.566z' fill='%23B0B1B4'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(-180 7 4)' d='M0 0h14v8H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            background-origin: content-box;
            background-repeat: no-repeat;
            background-size: 14px 8px;
            background-position: right -11px center;
        }
    }

    .form-control {
        @extend .body-2-text-light;

        &:not(select) {
            padding: 17px 30px 5px 15px;
        }

        &:placeholder-shown + label {
            transform: translateY(7px);
            width: auto;
            white-space: normal;
            overflow: initial;
        }

        &:not(textarea) {
            height: 45px;
        }

        &[type="search"]::-webkit-search-decoration,
        &[type="search"]::-webkit-search-cancel-button,
        &[type="search"]::-webkit-search-results-button,
        &[type="search"]::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border: 1px solid $primary-color;
            box-shadow: none;
        }

        &:focus,
        &:-webkit-autofill {
            + label {
                transform: translateY(0) scale(0.6);
                top: 4px;
                width: calc(160% - 36px);
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &:disabled,
        &[readonly] {
            color: rgba(0, 0, 0, 0.6);
            background: #e1e1e1;

            &:not(.is-invalid) {
                border: 1px solid #e1e1e1;
            }

            + label {
                color: rgba(0, 0, 0, 0.6);
            }

            + .dropdown .dropdown-toggle {
                border: 1px solid #e1e1e1;
                background: #e1e1e1;
                color: rgba(0, 0, 0, 0.6);
                pointer-events: none;
                opacity: 1;
            }
        }

        &::placeholder {
            opacity: 0;
        }

        &::after {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
        }

        &.is-valid {
            // background: none;
            // background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231BB934' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
            // background-repeat: no-repeat;
            // background-position: right calc(0.25em + 0.4rem) center;
            // background-size: 10px;
        }

        &.is-invalid {
            // background: none;
            // background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23b40303' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'%3E%3C/path%3E%3C/svg%3E");
            // background-repeat: no-repeat;
            // background-position: right calc(0.25em + 0.4rem) center;
            // background-size: 9px;

            + .dropdown:not(.show) .dropdown-toggle {
                border: 1px solid $danger;
            }
        }
    }

    .numeric-input-thick {
        font-size: 18px;
        font-weight: $font-weight-bold;
        letter-spacing: 0.14px;
        color: $black;
        padding: 0 !important;
        text-align: center;
        border: 3px solid #162056;

        @include media-breakpoint-down(md) {
            border: 1px solid #ced4da;
        }
    }

    .select-thick {
        &.form-control {
            padding: 0;
            text-align: center;
            color: #162056;
            font-weight: 700;
            justify-content: center;
            background-position-x: 95%;
        }

        .dropdown-toggle {
            padding: 0;
            text-align: center;
            border: 3px solid #162056;
            color: #162056;
            font-weight: 700;
            justify-content: center;

            &::after {
                position: absolute;
                right: 15px;
                color: #162056;
            }
        }
    }

    .numeric-input-buttons {
        gap: 1rem;
        min-height: 68px;

        .numeric-input-buttons-field::-webkit-outer-spin-button,
        .numeric-input-buttons-field::-webkit-inner-spin-button {
            appearance: none;
        }

        .numeric-input-buttons-field-container {
            @extend .french-gradient-border-input-container;
        }

        .numeric-input-buttons-field {
            @extend .body-1-text-bold;

            color: $black;
            height: 45px;
            text-align: center;

            @include media-breakpoint-down(xs) {
                min-width: unset;
                max-width: 120px;
            }
        }

        .numeric-input-btn {
            @extend .french-gradient-border;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            padding: 15px;
            border: 0;
            cursor: pointer;

            svg {
                width: 20px;
            }
        }
    }
}

.invalid-feedback {
    color: $danger;
    font-size: 15px;
    line-height: 19px;
}

.is-invalid {
    .invalid-feedback {
        display: block;
    }
}

.switch {
    .switch-label {
        @extend .body-1-text-light;

        small {
            opacity: 0.5;
        }
    }

    .custom-switch {
        $control-switch-height: 32px;
        $control-switch-width: 60px;

        padding-left: $control-switch-width;

        .custom-control-label {
            &::before {
                top: -0.25rem;
                left: -$control-switch-width;
                width: $control-switch-width;
                height: $control-switch-height;
                border-radius: $control-switch-height;
            }

            &::after {
                top: calc(-0.25rem + 3px);
                left: calc(-#{$control-switch-width} + 3px);
                width: calc(#{$control-switch-height} - 6px);
                height: calc(#{$control-switch-height} - 6px);
                background-color: #d8d8d8;
                border-radius: 3.5rem;
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::after {
                background-color: $primary-color;
                transform: translateX(26px);
            }

            &::before {
                background-color: white;
                border-color: $primary-color;
            }
        }

        .custom-control-input:disabled {
            &:checked ~ .custom-control-label::before {
                border-color: $primary-color;
                background-color: white;
            }
        }
    }
}
