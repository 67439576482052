@mixin fixed-bottom-bar {
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0 -4px 4px 0 rgba(162, 162, 162, 0.5);
    padding: 10px;
}

@include media-breakpoint-down(md) {
    .fixed-bottom-bar {
        @include fixed-bottom-bar;

        margin: 0 !important;
    }
}

svg {
    position: relative;
    display: inline-block;
    // width: 100%;
    // height: 100%;
    fill: currentcolor;
    vertical-align: middle;
    // top: -0.063em;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.loading-icon {
    animation: spin 1.5s linear infinite;
}

.text-dark-blue {
    color: $darkBlue;
}

.promo-icon {
    align-self: start;
    padding-bottom: 30px;

    .promo {
        font-size: 14px;
        border: 1px solid;
        padding: 3px 10px;
        color: white;
        background-color: #141e5b;
        font-weight: 700;
        margin: 0;
    }

    .pr-azure {
        background-color: #48c0d4;
    }
}

.optanon-alert-box-wrapper {
    .optanon-alert-box-button-middle {
        border-radius: 20px;
        background-color: white !important;
        color: $primary !important;
        border: none !important;

        &:hover {
            color: $primary !important;
        }

        button.accept-cookies-button {
            color: $primary !important;

            &::before {
                content: "" !important;
            }
        }

        .cookie-settings-button {
            &::before {
                content: "" !important;
            }
        }
    }

    .banner-close-button.banner-close-button {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNTIgNTEyIj48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTI0Mi43MiAyNTZsMTAwLjA3LTEwMC4wN2MxMi4yOC0xMi4yOCAxMi4yOC0zMi4xOSAwLTQ0LjQ4bC0yMi4yNC0yMi4yNGMtMTIuMjgtMTIuMjgtMzIuMTktMTIuMjgtNDQuNDggMEwxNzYgMTg5LjI4IDc1LjkzIDg5LjIxYy0xMi4yOC0xMi4yOC0zMi4xOS0xMi4yOC00NC40OCAwTDkuMjEgMTExLjQ1Yy0xMi4yOCAxMi4yOC0xMi4yOCAzMi4xOSAwIDQ0LjQ4TDEwOS4yOCAyNTYgOS4yMSAzNTYuMDdjLTEyLjI4IDEyLjI4LTEyLjI4IDMyLjE5IDAgNDQuNDhsMjIuMjQgMjIuMjRjMTIuMjggMTIuMjggMzIuMiAxMi4yOCA0NC40OCAwTDE3NiAzMjIuNzJsMTAwLjA3IDEwMC4wN2MxMi4yOCAxMi4yOCAzMi4yIDEyLjI4IDQ0LjQ4IDBsMjIuMjQtMjIuMjRjMTIuMjgtMTIuMjggMTIuMjgtMzIuMTkgMC00NC40OEwyNDIuNzIgMjU2eiIvPjwvc3ZnPgo=") !important;
        background-size: 14px 14px;
        height: 14px;
        width: 14px;
        top: 10px;
    }
}

.visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

@mixin flex-auto {
    flex: 0 0 auto;
}

//

@mixin flex-shrink {
    flex: 0 1 auto;
}

.flex-auto {
    @include flex-auto;
}

.flex-shrink {
    @include flex-shrink;
}

.flex-fill {
    flex-grow: 1;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.pos-relative {
    position: relative;
}
