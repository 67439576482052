.notfound {
    position: relative;

    .container-image {
        position: relative;

        .image {
            height: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .container {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .notfound-header {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 35px);

        @include media-breakpoint-up(lg) {
            width: calc(100% - 30px);
        }

        .title {
            font-size: 85px;
            line-height: 70px;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                font-size: 100px;
                line-height: 70px;
                margin-bottom: 25px;
            }
        }

        p {
            font-size: 25px;
            line-height: 33px;
            margin-bottom: 26px;

            @include media-breakpoint-up(lg) {
                font-size: 30px;
                margin-bottom: 40px;
                line-height: 40px;
                width: 462px;
            }
        }

        a {
            height: 55px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            padding: 13px 50px;
            border-radius: 50px;

            @include media-breakpoint-up(md) {
                width: 330px;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
}
