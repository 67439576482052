.table-collapse-toggle {
    .toggle-text {
        .less {
            display: none;
        }

        &.open {
            .more {
                display: none;
            }

            .less {
                display: inline-block;
            }
        }

        svg {
            width: 1em;
            height: 1em;
        }
    }
}
