@use "sass:math";

/* Typo */
.heading-1 {
    font-family: $font-edenred-base;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.25px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
        font-size: 35px;
        line-height: 45px;
        letter-spacing: 0;
    }
}

.heading-1-hero {
    @include media-breakpoint-up(lg) {
        font-size: 70px;
        line-height: 75px;
        letter-spacing: 0;
        color: #fff;
        margin-bottom: 13px;
    }
}

.heading-2 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.14px;
    }
}

.heading-2-hero {
    @include media-breakpoint-up(lg) {
        font-size: 50px;
        line-height: 60px;
        letter-spacing: 0;
        margin-bottom: 16px;
    }
}

.heading-3 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.14px;
    }
}

.heading-4 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.25px;
        font-weight: $font-weight-medium;
    }
}

.body-1-text,
.body-1-text-bold,
.body-1-text-medium,
.body-1-text-light {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.25px;

    @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.14px;
    }
}

.body-2-text,
.body-2-text-bold,
.body-2-text-medium,
.body-2-text-light {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.label-text,
.label-text-bold,
.label-text-medium,
.label-text-light {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.caption-text,
.caption-text-bold,
.caption-text-medium,
.caption-text-light,
.breadcrumb-text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15px;
    font-weight: $font-weight-light;
}

.footer-small-text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    font-weight: $font-weight-light;
    color: #fff;

    a,
    button {
        color: #fff;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: #fff;
        }
    }
}

.body-1-text,
.body-2-text,
.label-text,
.caption-text {
    font-weight: $font-weight-base;
}

.body-1-text-bold,
.body-2-text-bold,
.label-text-bold,
.caption-text-bold {
    font-weight: $font-weight-bold;
}

.body-1-text-light,
.body-2-text-light,
.label-text-light,
.caption-text-light {
    font-weight: $font-weight-light;
}

.body-1-text-medium,
.body-2-text-medium,
.label-text-medium,
.caption-text-medium {
    font-weight: $font-weight-medium;
}

.bold-input {
    font-size: 22px;
    font-weight: $font-weight-bold;
    line-height: 32px;
}

.cta-text {
    font-size: 16px;
    letter-spacing: 0.14px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}

.cta-text-small {
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    font-weight: $font-weight-bold;
    color: $primary-color;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}

/* Boxes */
.box-spacing-default {
    margin-bottom: $box-spacing-xs;

    @include media-breakpoint-up(lg) {
        margin-bottom: $box-spacing-lg;
    }
}

.box-spacing-50 {
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
    }
}

.box-rounded-default {
    border-radius: $box-radius-default;
    border: 1px solid $gray;
}

/* Gradient texts */
.green-element-text {
    // Edenred/Green elements
    @include make-gradient-text($green-element-gradient);
}

.green-cta-text {
    // Edenred/Green CTA
    @include make-gradient-text($green-cta-gradient);
}

.blue-cta-text {
    @include make-gradient-text($cta-linear-gradient-darker);

    text-decoration-color: $cta-linear-gradient-base;

    u {
        text-decoration-color: $cta-linear-gradient-base;
    }
}

.keyword-gradient-text {
    @include make-gradient-text($keyword-gradient);
}

.keyword-gradient-text-title-sup {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    margin-top: -5px;
}

/* Gradient borders */
.french-gradient-border {
    position: relative;
    background: $color-francia-gradient;
    border-radius: $box-radius-default;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: $box-radius-default - 2px;
        background-color: $white;
    }

    > * {
        position: relative;
        z-index: 1;
    }
}

.french-gradient-border-input-container {
    position: relative;
    background: $color-francia-gradient;
    border-radius: $box-radius-default;

    &:has(.is-invalid) {
        background: $danger;
    }

    .form-control {
        z-index: 10;
        border: 2px solid transparent;
        border-radius: $box-radius-default - 1px;

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
            border: 2px solid transparent;
        }
    }
}

/* global use */
.step-intro {
    @extend .body-1-text-light;

    margin-bottom: 20px;
}

.icon-plus-francia,
.icon-minus-francia {
    display: inline-block;
    width: 13px;
    height: 13px;
}

.icon-plus-francia {
    background: $icon-plus-francia no-repeat center center;
}

.icon-minus-francia {
    background: $icon-minus-francia no-repeat center center;
}

.tooltip .tooltip-inner {
    @extend .body-2-text;

    text-align: center;
    border-radius: math.div($box-radius-default, 2);
    background: $blue-gradient;
}

.bullet-step-number {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    background: $blue-radial-gradient;
}
